import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import PropTypes from 'prop-types'
import ThemeContext from "../context/ThemeContext"
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Content from './Content'
import Form from './Form'
import ReactMarkdown from 'react-markdown'

export default class About extends React.Component {
  componentDidMount() {
    this.context.hideNav()
  }
  render() {
    const { title, illustration, heading1, content, contentComponent, image, heading2, bio } = this.props
    const PostContent = contentComponent || Content

    return (
      <ThemeContext.Consumer>
        {theme => (
          <div className="cAbout cSplitScreen">
            <div className="cAbout-left cSplitScreen-content">
              <div className="cSplitScreen-heading">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-title">Cleo's Books</p>
                <AniLink fade to={theme.prevPath ? theme.prevPath : '/'} duration={0.25}  className="close-icon" aria-label="Close"></AniLink>
              </div>
              <div className="cSplitScreen-path">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-path-text"><AniLink fade duration={0.25} to="/menu">Menu</AniLink> / {title}</p>
                <AniLink fade to={theme.prevPath ? theme.prevPath : '/'} duration={0.25}  className="close-icon" aria-label="Close"></AniLink>
              </div>
              <div className="cAbout-column">
                <div className="cAbout-image">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: illustration,
                      alt: `illustration for ${heading1}`
                    }}
                  />
                </div>
                <h2 className="cAbout-title">{heading1}</h2>
                <div className="cAbout-text">
                <ReactMarkdown
                  source={content}
                  escapeHtml={false}
                />
                </div>
              </div>
              <div className="cAbout-row">
                <div className="cAbout-image">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: image,
                      alt: `image for ${heading2}`
                    }}
                  />
                </div>
                <div className="cAbout-content">
                  <h2 className="cAbout-title">{heading2}</h2>
                  <div className="cAbout-text">
                  <ReactMarkdown
                    source={bio}
                    escapeHtml={false}
                  />
                  </div>
                </div>
              </div>
              <div className="cAbout-row">
                <Form />
              </div>
            </div>
            <AniLink className="cAbout-right cSplitScreen-cover" fade to={theme.prevPath ? theme.prevPath : '/'} duration={0.25} >
              <p className="cSplitScreen-cover-title">Cleo's Books</p>
            </AniLink>
          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

About.contextType = ThemeContext

About.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  })
}
