import React from 'react'
export default class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name:'',
      error:'',
      email:'',
      message:'',
      loading: false
    }
  }
  handleSubmit(e) {
    e.preventDefault()
    if (this.state.loading) {
      return
    }
    if (!this.state.name || this.state.name.length < 3) {
      this.setState({
        error: 'Please enter your full name'
      })
      return
    }
    if (!this.state.message || this.state.message.length < 5) {
      this.setState({
        error: 'Please enter a message'
      })
      return
    }
    if (!this.state.email || this.state.email.indexOf('@') === -1 || this.state.email.indexOf('.') <= this.state.email.indexOf('@')) {
      this.setState({
        error: 'Please enter a valid email address'
      })
      return
    }
    this.setState({loading: true})
    fetch('/.netlify/functions/contact', {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
       from: this.state.email,
       name: this.state.name,
       text: this.state.message,
       fromReact: true
      })
     }).then(response => response.json())
     .then((data) => {
       console.log('Got data!', data)
       if (data && data.result && data.result.indexOf('could not be') === -1) {
        this.setState({
          sent: true
        })
       } else {
        this.setState({
          sent: false,
          loading: false,
          error: 'Your message could not be delivered. Please try again later.'
        })
       }
     })
  }
  handleChange(e) {
    const state = {
      error: ''
    }
    state[e.currentTarget.attributes.name.nodeValue] = e.currentTarget.value
    this.setState(state)
  }
  render() {
    return (
      <div className="cForm">
        <h2 className="cForm-title">Contact</h2>
        {this.state.error ? (<div className="cForm-error">{ this.state.error }</div>) :  null}
        {this.state.sent ? (<div className="cForm-success">Your message was sent!</div>) :  null}

        <form style={this.state.sent ? {display: 'none'} : null} className="cForm-column" action="#" onSubmit={this.handleSubmit.bind(this)}>
          <div className="cForm-row">
            <label className="visually-hidden" htmlFor="name">Name</label>
            <input disabled={this.state.loading || null} onChange={this.handleChange.bind(this)} className="cForm-input" type="text" id="name" name="name" placeholder="Name" />
            <label className="visually-hidden" htmlFor="email">Email</label>
            <input disabled={this.state.loading || null} onChange={this.handleChange.bind(this)} className="cForm-input" type="text" id="email" name="email" placeholder="Email Address" />
          </div>
          <div className="cForm-row">
            <label className="visually-hidden" htmlFor="subject">Message</label>
            <textarea disabled={this.state.loading || null} onChange={this.handleChange.bind(this)} className="cForm-textarea" id="subject" name="message" placeholder="Message" aria-label="Message"></textarea>
          </div>
          <input disabled={this.state.loading || null} className="cForm-btn" type="submit" value={this.state.loading ? 'Sending...' : 'Send Form'} />
        </form>
      </div>
    )
  }
}
