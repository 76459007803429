import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import About from '../components/About';

export const AboutPageTemplate = ({
  title,
  illustration,
  heading1,
  content,
  image,
  heading2,
  bio,
  contentComponent,
  helmet
}) => {

  return (
    <section className="section">
      {helmet || ''}
      <About
        title={title}
        illustration={illustration}
        heading1={heading1}
        content={content}
        image={image}
        heading2={heading2}
        bio={bio}
        contentComponent={contentComponent}
      />
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  illustration: PropTypes.object,
  heading1: PropTypes.string.isRequired,
  content: PropTypes.string,
  image: PropTypes.object,
  heading2: PropTypes.string,
  bio: PropTypes.string,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object,
}

const AboutPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        title={post.frontmatter.title}
        illustration={post.frontmatter.illustration}
        heading1={post.frontmatter.heading1}
        content={post.html}
        image={post.frontmatter.image}
        heading2={post.frontmatter.heading2}
        bio={post.frontmatter.bio}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${post.frontmatter.title}`}</title>
            <meta property="og:title" content={post && post.frontmatter && post.frontmatter.title} />
            <meta property="og:url" content={location && location.pathname} />
            <meta name="description" content={post && post.frontmatter && post.frontmatter.description} />
            <meta property="og:image" content={(post.frontmatter.image && post.frontmatter.image.childImageSharp && post.frontmatter.image.childImageSharp.fluid && post.frontmatter.image.childImageSharp.fluid.src) ||  "/img/book-logo.png"} />
          </Helmet>
        }
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  })
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        illustration {
          childImageSharp {
            fluid(maxWidth: 240, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading1
        image {
          childImageSharp {
            fluid(maxWidth: 240, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading2
        bio
      }
    }
  }
`
